import React from 'react'
//scss
import '../scss/page/successForm.scss'
const SuccessForm = () => {
  return (
    <div className='successForm'>
      <div className='container'>
        <h2>Envoie du message réussi:</h2>
        <p>
          Votre message a bien été envoyé, nous vous contacterons le plus vite
          possible afin de résoudre votre problème dans les plus bref délais.
        </p>
      </div>
    </div>
  )
}

export default SuccessForm
